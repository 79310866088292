const apiPath = {
  adminLogin: '/admin/api/login',
  dashboard: '/admin/api/dashboard',
  getTotalSales: '/admin/api/get_total_sales',
  adminPincodes: '/admin/api/pincodes',
  adminRestaurantData: '/restaurants',
  adminRestaurantStatusUpdate: '/restaurants/status',
  adminRestaurantArchive: '/restaurants/archive',
  addRestaurant: '/restaurants/add',
  updateRestaurant: '/restaurants/update',
  adminRestaurantList: '/restaurants/get_relation_data',
  cuisinesList: '/restaurants/cuisines/list',
  adminCuisineData: '/restaurants/cuisine',
  updateCuisine: '/restaurants/cuisine/update',
  addCuisine: '/restaurants/cuisine/add',
  editRestaurant: '/restaurants/update/',
  adminUsersPage: '/users',
  adminUserStatusUpdate: '/users/status',
  adminUserArchive: '/users/archive',
  adminCuisinesPage: '/restaurants/cuisines',
  adminCuisineStatusUpdate: '/restaurants/cuisine/status',
  adminCuisineArchive: '/restaurants/cuisine/archive',
  adminMenusPage: '/restaurants/menu_items',
  adminMenuData: '/restaurants/menu_item',
  adminMenuStatusUpdate: '/restaurants/menu_item/status',
  adminMenuAdd: '/restaurants/menu_item/add',
  adminMenuUpdate: '/restaurants/menu_item/update',
  adminMenuArchive: '/restaurants/menu_item/archive',
  adminOrdersPage: '/orders',
  adminOrderStatusUpdate: '/orders/status',
  get_blog_categories: '/blog/categories/api/list',
  create_blog: '/blogs/api/create',
  get_blog_list: '/blogs/api/bloglist',
  blogdetails: '/blogs/api/blogdetails',
  updtae_blog_details: '/blogs/api/update',
  delete_blog: '/blogs/api/delete_blog',
  get_category_list: '/blog_category/api/list',
  create_category: '/blog_category/api/create',
  delete_category: '/blog_category/api/delete_category',
  updtae_category_name: '/blog_category/api/update',
  get_sub_category_list: '/blog_category/api/sub_category_list',
  create_subcategory: '/blog_category/api/sub_create',
  updtae_sub_category: '/blog_category/api/sub_cat_update',
  banner_list: '/banner/api/list',
  create_banner: '/banner/api/create',
  delete_banner: '/banner/api/delete_banner',
  get_pincode_list: '/pincodes/api/list',
  get_citites_list: '/cities/api/list',
  add_pincode: '/pincodes/api/create',
  delete_pincode: '/pincodes/api/delete',
  updtae_pincode: '/pincodes/api/update',
  updtae_banner_details: '/banner/api/update',
  users_list: '/users/api/list',
  register_user: '/users/api/registration',
  send_token: '/users/api/send_token',
  reset_password: '/users/api/reset_password',
  create_user: '/users/api/user_registration',
  add_subadmin: '/users/api/add_subadmin',
  delete_subadmin: '/users/api/delete_subadmin',
  need_helps: '/admin/api/need_helps',
  user_details: '/users/api/show/',
  user_family_members: '/users/api/my_family_mambers',
  add_family_members: '/users/api/add_family_mamber',
  get_bookings_list: '/bookings/api/all_bookings',
  booking_details: '/bookings/api/booking_details/',
  coupons_list: '/coupons/api/coupon_list',
  search_location: '/cities/api/search_location/',
  search_test_or_package: '/common/api/search_test_or_package_name',
  add_coupon: '/coupons/api/add_coupon',
  download_coupon: '/coupons/api/download_coupons',
  edit_coupon: '/coupons/api/edit_coupon',
  coupon_details: '/coupons/api/show/',
  coupon_revenue_list: '/coupons/api/coupon_revenue_list',
  delete_coupon: '/common/api/model_row_deleted_by_admin',
  change_model_status: '/common/api/model_status_update_by_admin',
  update_status: '/admin/api/update_status',
  booking_earnings_list: '/bookings/api/booking_earning_list',
  user_ratings_list: '/ratings/api/user_rating_review_on_bookings',
  checkTimeSlotAvailability: '/bookings/api/check_time_slot_availability',
  admin_booking_source: '/bookings/api/admin_booking_source',

  faqCatList: '/admin/api/faq_categories',
  FaqCatAddOrUpdate: '/admin/api/add_or_update_faq_category',
  deleteFaqCat: '/admin/api/delete_faq_category',

  faqList: '/admin/api/faqs',
  FaqAddOrUpdate: '/admin/api/add_or_update_faq',
  deleteFaq: '/admin/api/delete_faq',
  checkPincode: '/users/api/check_pincode',
  updateAddress: '/users/api/update_address/',
  addAddress: '/users/api/add_address',
  uninstallUserStatus: '/users_app_status/api/app-status',

  //Role
  role_list: '/roles/api/list',
  roleAddOrUpdate: '/roles/api/roleAddOrUpdate',
  getModules: '/roles/api/getModules',
  getRoleName: '/roles/api/getRoleName',
  setPermission: '/roles/api/setPermission',
  getSubAdminRoles: '/roles/api/get_subadmin_roles',

  news_event_list: '/blogs/api/news_event_list',
  news_event_add_update: '/blogs/api/newsevent/add_or_update',
  news_event_details: '/blogs/api/news_event',
  news_event_delete: '/blogs/api/delete_event',

  //Maan
  get_all_cities: '/cities/api/list',
  get_app_settings: '/admin/api/get_app_settings',
  get_core_values: '/admin/api/get_core_values',
  delete_core_value: '/admin/api/delete_core_value',
  update_app_settings: '/admin/api/update_app_settings',
  update_core_values: '/admin/api/update_core_values',
  delete_row: '/admin/api/delete_row',
  user_prescriptions: '/users/api/user_prescriptions',
  user_prescription_details: '/users/api/prescriptionDetails',
  booking_time_slots: '/bookings/api/booking_time_slots',
  add_booking: '/bookings/api/add',
  get_address: '/users/api/get_address',
  update_password: '/admin/api/update_password',
  update_profile: '/admin/api/update_profile',
  search_users: '/users/api/search_users',
  send_notification: '/users/api/send_notification',
  content_list: '/common/api/content_list',
  content_details: '/common/api/content_details',
  get_health_bulletin_list: '/health_bulletin/api/list_health_bulletin',
  delete_health_bulletin: '/health_bulletin/api/delete_health_bulletin',
  create_health_bulletin: '/health_bulletin/api/create_health_bulletin',
  update_health_bulletin: '/health_bulletin/api/update_health_bulletin',
  get_latest_diagnostic_list: '/latest_diagnoestic_test/api/list_latest_diagnoestic_test',
  delete_latest_diagnostic: '/latest_diagnoestic_test/api/delete_latest_diagnoestic_test',
  create_latest_diagnostic: '/latest_diagnoestic_test/api/create_latest_diagnoestic_test',
  update_latest_diagnostic: '/latest_diagnoestic_test/api/update_latest_diagnoestic_test',
  get_refer_patient_list: '/refer_patient/api/list_refer_patient',
  delete_refer_patient: '/refer_patient/api/delete_refer_patient',
  create_refer_patient: '/refer_patient/api/create_refer_patient',
  update_refer_patient: '/refer_patient/api/update_refer_patient',
  create_happy_customers: '/happy_customers/api/create_happy_customers',
  get_happy_customers_list: '/happy_customers/api/list_happy_customers',
  delete_happy_customers: '/happy_customers/api/delete_happy_customers',
  get_happy_customers_list_byid: '/happy_customers/api/happy_customers',
  update_happy_customers: '/happy_customers/api/update_happy_customers',
  get_franchise_opportunity_list: '/franchise/api/list_franchise_opportunity',
  get_franchise_opportunity_list_byid: '/franchise/api/franchise_opportunity',
  delete_franchise_opportunity: '/franchise/api/delete_franchise_opportunity',
  get_hospital_lab_management_list: '/hospital_lab_management/api/list_hospital_lab_management',
  get_hospital_lab_management_list_byid: '/hospital_lab_management/api/hospital_lab_management',
  delete_hospital_management: '/hospital_lab_management/api/delete_hospital_lab_management',
  get_lab_acquisition_list: '/lab_acquisition/api/list_lab_acquisition',
  get_lab_acquisition_list_byid: '/lab_acquisition/api/lab_acquisition',
  delete_lab_acquisition: '/lab_acquisition/api/delete_lab_acquisition',
  get_regional_partner_list: '/regional_partner/api/list_regional_partner',
  get_regional_partner_list_byid: '/regional_partner/api/regional_partner',
  delete_regional_partner_list: '/regional_partner/api/delete_regional_partner',
  get_news_letter_list: '/news_letter/api/list_news_letter',
  get_news_letter_list_byid: '/news_letter/api/list_news_letter',
  delete_news_letter: '/news_letter/api/delete_news_letter',
  get_messages: '/message/api/list_messages',
  get_messages_byid: '/message/api/list_messages',
  delete_messages: '/message/api/delete_messages',
  create_our_clients: '/our_clients/api/create_our_clients',
  get_our_clients_list: '/our_clients/api/list_our_clients',
  get_our_clients_list_byid: '/our_clients/api/list_our_clients',
  update_our_clients: '/our_clients/api/update_our_clients',
  delete_our_clients: '/our_clients/api/delete_our_clients',
  create_awards: '/awards/api/create_awards',
  get_awards: '/awards/api/list_awards',
  get_awards_byid: '/awards/api/list_awards',
  update_awards: '/awards/api/update_awards',
  delete_awards: '/awards/api/delete_awards',
  get_feedback: '/feedback/api/list_feedback',
  get_feedback_byid: '/feedback/api/list_feedback',
  delete_feedback: '/feedback/api/delete_feedback',
  get_cities: '/cities/api/list',
  update_cities: 'cities/api/update',
  create_membership_purchase: '/membership_card_puchase/api/create_membership_purchase',
  booking_offers: '/coupons/api/offers',
  get_logs: '/logs/api/get-logs',
  get_app_status_report: '/users_app_status/api/get-users-report',
  life_at_blal: '/life_at_blal/api',
  core_members: '/core_members/api',
  change_booking_slot: '/bookings/api/change_booking_slots',
  fetchOffDayData: '/bookings/api/get_changed_booking_slots',
  add_city: 'cities/api/add',
  add_force_update: 'users_app_status/api/force-update'
};
export default apiPath;
