import React, { useState } from 'react';
import Button from '@mui/material/Button';
import apiPath from 'src/constants/apiPath';
import helper from 'src/constants/helper';
import { Backdrop, CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import useSession from 'src/hooks/useSession';
import { toast } from 'react-toastify';
import Controls from 'src/components/control/Controls';

const isValidVersion = (version) => {
    const semverRegex = /^\d+\.\d+\.\d+$/;
    return semverRegex.test(version);
};

const ForceUpdate = ({
    setShowUpdateModel
}) => {
    const session = useSession();
    const [isLoading, setLoading] = useState(false);
    const [platform, setPlatform] = useState('')
    const [latestVersion, setLatestVersion] = useState('')
    const [requiredVersion, setRequiredVersion] = useState('')
    const [error, setError] = useState({});

    const handleForceUpdate = async () => {
        try {
            setLoading(true);
            setError('');
            let url = `/${apiPath.add_force_update}`;
            if(latestVersion && !isValidVersion(latestVersion)){
                setError({...error, latestVersion: "Invalid version"})
                return;
            }
            if(requiredVersion && !isValidVersion(requiredVersion)){
                setError({...error, requiredVersion: "Invalid version"})
                return;
            }
            if (platform && latestVersion) {
                const data = {
                    platform: platform,
                    latest_version: latestVersion,
                    required_version: requiredVersion
                }
                const res = await helper.post(data, url, session);

                if (res?.data?.status === 200) {
                    setShowUpdateModel(false)
                    toast.success(res?.data?.message);
                } else {
                    toast.error(res?.data?.message);
                }
            }else{
                setError({...error,platform: "Please select platform type"});
                return;
            }
        } catch (e) {
            console.error(e);
            setError('Something went wrong!');
            toast.error(e?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Backdrop
                sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                open={isLoading}
            >
                {' '}
                <CircularProgress color="inherit" />
            </Backdrop>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '40px' }}>
                <div style={{ display: 'flex', gap: '25px', flexWrap: 'wrap' }}>
                    <FormControl sx={{ m: 1, width: 210, marginTop: '0%' }}>
                        <InputLabel id="demo-simple-select-label">Select Platform*</InputLabel>
                        <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Select Platform*"
                            onChange={(e) => {
                                setPlatform(e.target.value);
                            }}
                        >
                            <MenuItem value="android">Android</MenuItem>
                            <MenuItem value="ios">IOS</MenuItem>
                        </Select>
                    </FormControl>
                    <Controls.Input
                        type="text"
                        name="latestVersion"
                        value={latestVersion}
                        label="Latest Version*"
                        onChange={(e) => {
                            setLatestVersion(e.target.value)
                        }}
                        error={error.latestVersion ? error.latestVersion : ''}
                        helperText={error.latestVersion && error.latestVersion}
                    />
                    <Controls.Input
                        type="text"
                        name="requiredVersion"
                        value={requiredVersion}
                        label="Minimum Version"
                        onChange={(e) => {
                            setRequiredVersion(e.target.value)
                        }}
                        error={error.requiredVersion ? error.requiredVersion : ''}
                        helperText={error.requiredVersion && error.requiredVersion}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="contained" color="primary" onClick={handleForceUpdate}>
                        Send Force Update
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ForceUpdate;
