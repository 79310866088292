import { useEffect, useState } from 'react';
import { Search } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import Page from '../../components/Page';
import apiPath from '../../constants/apiPath';
import helper from '../../constants/helper';
import ReportGenerator from './GetReportPopUp';
import 'react-toastify/dist/ReactToastify.css';

import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Container,
  Grid,
  InputAdornment,
  Link,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Backdrop } from '@mui/material';
import { capitalize, localDate } from 'src/utilities';
import Popup from 'src/components/Popup';
import Button from 'src/components/control/Button';
import useSession from 'src/hooks/useSession';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs'

export default function UserAppStatus() {
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const session = useSession();
  const [uninstallAppUserData, setUninstallAppUserData] = useState([]);
  const [selectedDate, setDateChange] = useState([null, null]);
  let [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const [searchKeyword, setSearchKeyword] = useState('');
  const [nameQuery, setNameQuery] = useState('');
  const [numberQuery, setNumberQuery] = useState('');
  const [dateQuery, setDateQuery] = useState({});
  const [openReportPopUp, setOpenReportPopUp] = useState(false);

  const handleSearch = (value) => {
    let target = value;
    if (target && target.length > 2) {
      if (!isNaN(parseFloat(target))) {
        setNumberQuery(target);
        getUninstallUsersData('', target, dateQuery.startDate, dateQuery.endDate);
      } else {
        setNameQuery(target);
        getUninstallUsersData(target, '', dateQuery.startDate, dateQuery.endDate);
      }
    } else if (!target) {
      setNameQuery('');
      setNumberQuery('');
      getUninstallUsersData('', '', dateQuery.startDate, dateQuery.endDate);
    }
    setSearchKeyword(target); // Move the setSearchKeyword here
  };

  const handleOpenReportPopUp = () => {
    setOpenReportPopUp(true);
  };

  useEffect(() => {
    if (startDate && endDate) {
      const startdate = localDate(startDate, 'date') + ' 00:00:00'; // setting hard code start time
      const enddate = localDate(endDate, 'date') + ' 23:59:59'; // setting hard code end time
      setDateQuery({ startdate, enddate });
      getUninstallUsersData(nameQuery, numberQuery, startdate, enddate);
    }
  }, [startDate, endDate])

  const handleDateChange = (newValue) => {
    if (newValue !== null && newValue.length === 2) {
      let [startDate, endDate] = newValue;
      if (startDate && endDate) {
        startDate = localDate(startDate, 'date') + ' 00:00:00'; // setting hard code start time
        endDate = localDate(endDate, 'date') + ' 23:59:59'; // setting hard code end time
        setDateQuery({ startDate, endDate });
        getUninstallUsersData(nameQuery, numberQuery, startDate, endDate);
      }
    }
  };

  const getUninstallUsersData = async (name, mobile, startDate, endDate) => {
    try {
      setLoading(true);
      let url = `${apiPath.uninstallUserStatus}?page=1`;
      if (name) {
        url = `${url}&name=${name}`;
      }
      if (mobile) {
        url = `${url}&mobileNumber=${mobile}`;
      }

      if (startDate && endDate) url = `${url}&startDate=${startDate}&endDate=${endDate}`;
      const res = await helper.get(url, session);
      if (res.status === 200) {
        setUninstallAppUserData(res.data.data.userAppStatus);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const clearAllFilters = () => {
    setNameQuery('');
    setNumberQuery('');
    setDateQuery({ startDate: '', endDate: '' });
    setSearchKeyword('');
    setDateChange([null, null]);
    getUninstallUsersData('', '', '', '');
  };

  useEffect(() => {
    getUninstallUsersData();
  }, []);

  const headCells = [
    { id: 'name', label: 'User', disableSorting: true },
    { id: 'mobile_number', label: 'Contact No', disableSorting: true },
    { id: 'last_updated', label: 'Last Updated', disableSorting: true }
  ];

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    uninstallAppUserData,
    headCells,
    filterFn,
    searchKeyword
  );

  return (
    <>
      <Page title={'Uninstalled App Users | Dr. B Lal'}>
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Backdrop
          sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={false}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                Uninstalled Mobile APP Users List
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">uninstall app users </Typography>
              </Breadcrumbs>
            </Grid>
              <Button onClick={handleOpenReportPopUp} text="Get Report" />
          </Stack>

          <Toolbar>
            <Stack direction="row" style={{ justifyContent: 'space-between', flex: 0.8 }}>
              <TextField
                style={{ width: '30%' }}
                label="Search Users"
                value={searchKeyword}
                className={'searchInput'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
                onChange={(e) => handleSearch(e.target.value)}
              />
              <div style={{ display: 'flex', alignItems: "center", gap: '15px' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From Date"
                    maxDate={dayjs(new Date())}
                    value={dayjs(startDate)}
                    onChange={(newValue) => {
                      setStartDate(dayjs(newValue));
                    }}
                  />
                  <span>to</span>
                  <DatePicker
                    label="To Date"
                    maxDate={dayjs(new Date())}
                    value={dayjs(endDate)}
                    onChange={(newValue) => {
                      setEndDate(dayjs(newValue));
                    }}
                  />
                </LocalizationProvider>
              </div>
              <Controls.Button
                text="Clear Filters"
                variant="outlined"
                className={'newButton'}
                onClick={clearAllFilters}
              />
            </Stack>
          </Toolbar>

          <TblContainer>
            <TblHeader />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{capitalize(item.name)}</TableCell>
                  <TableCell>{item.mobile_number}</TableCell>
                  <TableCell>{item.last_updated}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Container>
      </Page>
      <Popup
        title="Get Report of Uninstall App Users"
        openPopup={openReportPopUp}
        setOpenPopup={setOpenReportPopUp}
      >
        <ReportGenerator />
      </Popup>
    </>
  );
}
