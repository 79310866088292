import React from 'react';
import { useState, useEffect, useRef } from 'react';
import {
  FormControl,
  InputLabel,
  Grid,
  Box,
  TextField,
  Select,
  Autocomplete,
  MenuItem,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Controls from '../../components/control/Controls';
import { Form } from '../../components/useForm';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import useSession from 'src/hooks/useSession';

export default function Add(props) {
  let { addorEdit, recordForEdit } = props;
  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [testData, setTestData] = useState([]);
  const [description, setDescription] = useState('');
  const [linkType, setLinkType] = useState('');
  const [link_value, setlink_value] = useState('');
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [bannerType, setBannerType] = useState('Home');
  const [image, setImage] = useState('');
  const [webImage, setWebImage] = useState('');
  const [doctorAppImage, setDoctorAppImage] = useState('');
  const [preview, setPreview] = useState('');
  const [bannerFormError, setBannerFormError] = useState({});
  const [loadingDisable, setLoadingDisable] = useState(false);
  const [isTestOrPackageType, setIsTestOrPackageType] = useState(false);
  const [appCheck, setAppCheck] = useState("doctorApp");
  const session = useSession();
  const [applicationType, setApplicationType] = useState("both");
  const [priority, setPriority] = useState("");
  const [isDefaultValue, setIsDefaultValue] = useState(false);

  const getTestPackagesList = async () => {
    try {
      if (
        searchKeyword &&
        searchKeyword.length > 2 &&
        (linkType === 'Test' || linkType === 'Package')
      ) {
        const URL = `/GetFilterTestPackage?CityId=1&PanelId=78&Type=${linkType}&SearchKeyword=${searchKeyword}`;
        const res = await helper.postb(URL);
        if (res.status === 200) {
          if (res.data.status_Code === 200) {
            setlink_value(res.data.data.itemmodel[0]?.Slug);
            setTestData(res.data.data.itemmodel);
          }
        }
      }
    } catch (error) {
      console.log(`error in finaly catch is ${error.message}`);
    }
  };

  const getTestPackageDetails = async (type, value) => {
    try {
      const response =
        await helper.postb(`/GetTestPackageDetails?PanelId=78&CityId=1&Id=${value}&Type=${type}
      `);
      if (response.status === 200) {
        if (response.data.status_Code === 200) {
          setSearchKeyword(response.data.data.NAME);
          setlink_value(response.data.data);
          setLinkType(type);
          setIsTestOrPackageType(true);
        }
      }
    } catch (error) { }
  };

  const getBlogs = async () => {
    try {
      if (searchKeyword && searchKeyword.length > 2 && linkType === 'Blog') {
        const res = await helper.get(`/blogs/api/bloglist?title=${searchKeyword}`);
        if (res.status === 200) {
          if (res.data.success === true) {
            setlink_value(res?.data?.data[0]?.id);
            setTestData(res.data.data);
          }
        }
      }
    } catch (error) {
      console.log(`error in finaly catch is ${error.message}`);
    }
  };

  const getBlog = async (blog_id) => {
    try {
      const res = await helper.get(`/blogs/api/blogdetails?slug=${blog_id}`);
      if (res.status === 200) {
        if (res.data.success === true) {
          setSearchKeyword(res.data.data.title);
          setlink_value(res.data.data);
          setLinkType('Blog');
          setIsTestOrPackageType(true);
        }
      }
    } catch (error) {
      console.log(`error in finaly catch is ${error.message}`);
    }
  };

  const getBlogCategories = async () => {
    try {
      const res = await helper.get(`${apiPath.get_blog_categories}?name=${searchKeyword}`, session);
      if (res.status === 200) {
        if (res.data.success === true) {
          setlink_value(res.data.data[0].id);
          setTestData(res.data.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getBlogCategory = async (category_id) => {
    try {
      const res = await helper.get(
        `${apiPath.get_blog_categories}?categoryId=${category_id}`,
        session
      );
      if (res.status === 200) {
        if (res.data.success === true) {
          setSearchKeyword(res.data.data[0].name);
          setlink_value(res.data.data[0]);
          setLinkType('Category');
          setIsTestOrPackageType(true);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getNewEvents = async () => {
    try {
      const res = await helper.get(`${apiPath.news_event_list}?title=${searchKeyword}`, session);
      if (res.status === 200) {
        if (res.data.success === true) {
          setlink_value(res.data.data[0].id);
          setTestData(res.data.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getNewsEvent = async (id) => {
    try {
      const res = await helper.get(`${apiPath.news_event_details}/${id}`, session);
      if (res.status === 200) {
        if (res.data.success === true) {
          setSearchKeyword(res.data.data.title);
          setlink_value(res.data.data);
          setLinkType('NewsEvent');
          setIsTestOrPackageType(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMembershipCards = async (Slug = null) => {
    try {
      let url = '/getMembershipCardList?CityId=1'
      if (Slug) {
        url = `${url}&CardId=${Slug}`
      }
      const response =
        await helper.postb(url);
      if (response.status === 200) {
        if (response.data.status_Code === 200) {
          setSearchKeyword(response.data.data.NAME);
          setlink_value(response.data.data);
          setTestData(response.data.data);
          setLinkType('MembershipCard');
          setIsTestOrPackageType(true);
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (linkType === 'Test' || linkType === 'Package') {
      getTestPackagesList();
    }

    if (linkType === 'Blog') {
      getBlogs();
    }

    if (linkType === 'Category') {
      getBlogCategories();
    }

    if (linkType === 'NewsEvent') {
      getNewEvents();
    }

    if (linkType === 'MembershipCard') {
      getMembershipCards()
    }
  }, [searchKeyword]);

  let el = useRef();

  const getFile = (e) => {
    const image_name = e.target.name;
    let ext = e.target.value.match(/\.([^\.]+)$/)[1];
    switch (ext) {
      case 'png':
      case 'jpeg':
      case 'jpg':
        const file = e.target.files[0];
        if (image_name == 'web_image') {
          setWebImage(file);
        } else if (image_name === 'image_doctor_app') {
          setDoctorAppImage(file);
        } else {
          setImage(file);
        }
        break;
      default:
        alert('Please upload supported attachment file format (.png, .jpeg, .jpg).');
        e.target.value = '';
    }
  };

  const handleSubmit = async (e) => {
    try {
      setLoadingDisable(true);
      e.preventDefault();
      let data = new FormData();
      data.append('image', image);
      data.append('image_web', webImage);
      data.append('image_doctor_app', doctorAppImage);
      data.append('title', title);
      data.append('is_doctor_app', appCheck === 'doctorApp' ? true : false);
      data.append('description', description);
      data.append('link_type', linkType);
      data.append('applicationType', appCheck === "customerApp" 
                                      ? applicationType 
                                      : appCheck === "doctorApp" 
                                        ? "app"
                                        : "");
      let linkValueNew = link_value;
      if (linkType === 'Test' || linkType === 'Package') {
        if (link_value) {
          linkValueNew = link_value?.Slug;
        }
      }

      if (linkType === 'Blog') {
        if (link_value) {
          linkValueNew = link_value?.slug;
        }
      }

      if (linkType === 'Category') {
        if (link_value) {
          linkValueNew = link_value.id;
        }
      }

      if (linkType === 'NewsEvent') {
        if (link_value) {
          linkValueNew = link_value.id;
        }
      }

      if (linkType === 'MembershipCard') {
        if (link_value) {
          linkValueNew = `${link_value?.Slug}?id=${link_value?.Id}`;
        }
      }
      data.append('link_value', linkValueNew);
      data.append('type', bannerType);
      data.append('id', id);
      if (bannerType === "Home" && appCheck === "customerApp") {
        data.append('priority', priority);
      }

      let response = await addorEdit(data, id);

      if (response && response.data && response.data.errors) {
        setBannerFormError(response.data.errors);
        setLoadingDisable(false);
      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoadingDisable(false);
    }
  };

  useEffect(() => {
    if (recordForEdit) {
      setIsDefaultValue(true);
      setId(recordForEdit.id);
      setBannerType(recordForEdit.type);
      setTitle(recordForEdit.title);
      setDescription(recordForEdit.description);
      setAppCheck(recordForEdit.is_doctor_app ? 'doctorApp' : 'customerApp')
      setApplicationType(recordForEdit.application_type);
      setPriority(recordForEdit.priority);
      if (recordForEdit.link_type === 'Test' || recordForEdit.link_type === 'Package') {
        getTestPackageDetails(recordForEdit.link_type, recordForEdit.link_value);
      } else if (recordForEdit.link_type === 'Blog') {
        getBlog(recordForEdit.link_value);
      } else if (recordForEdit.link_type === 'Category') {
        getBlogCategory(recordForEdit.link_value);
      } else if (recordForEdit.link_type === 'NewsEvent') {
        getNewsEvent(recordForEdit.link_value);
      } else if (recordForEdit.link_type === 'MembershipCard') {
        const id = recordForEdit?.link_value.split("?")[1].split("=")[1]
        getMembershipCards(id);
        // setLinkType('MembershipCard');
      }
      else {
        setLinkType(recordForEdit.link_type);
        setlink_value(recordForEdit.link_value);
      }
      setPreview(recordForEdit.image);
    }
  }, [recordForEdit]);

  const handleChange = (e) => {
    setAppCheck(e.target.value);
  }

  return (
    <Form onSubmit={handleSubmit} title="Add Category | Dr. B. Lal">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controls.Input
            name="title"
            fullWidth
            label="Banner Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            error={bannerFormError?.title}
          />
        </Grid>
        <Grid item xs={12}>
          <Controls.Input
            name="title"
            rows={5}
            multiline={true}
            fullWidth
            label="Banner Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            error={bannerFormError?.description}
          />
        </Grid>
      </Grid>
      <Grid>
        <FormControl component="fieldset">
          <FormLabel component="legend">App check</FormLabel>
          <RadioGroup
            aria-label="App Check"
            name="appCheck"
            value={appCheck}
            onChange={handleChange}
          >
            <FormControlLabel value="doctorApp" control={<Radio />} label="Doctor App" />
            <FormControlLabel value="customerApp" control={<Radio />} label="Customer App" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Banner Type</InputLabel>
          <Select
            fullWidth
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Banner Type"
            value={bannerType}
            onChange={(e) => {
              setBannerType(e.target.value);
            }}
          >
            <MenuItem value="Home">Home Banner</MenuItem>
            <MenuItem value="Blog">Blog Banner</MenuItem>
            <MenuItem value="Popup">Popup Banner</MenuItem>
            <MenuItem value="CartBanner">Cart Banner</MenuItem>
            <MenuItem value="Partner">Partner With Us</MenuItem>
            <MenuItem value="LifeAtBlal">Life At Blal</MenuItem>
            <MenuItem value="Career">Career</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {appCheck === 'customerApp' ? (
        <>
          <Grid item xs={12}>
            <FormControl>
              <InputLabel id="demo-simple-select-label">Application Type</InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Application Type"
                value={applicationType}
                onChange={(e) => {
                  setApplicationType(e.target.value);
                }}
              >
                <MenuItem value="app">App</MenuItem>
                <MenuItem value="website">Website</MenuItem>
                <MenuItem value="both">Both</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {bannerType === 'Home' && appCheck === 'customerApp' ? (
            <Controls.Input
              name="priority"
              type="number"
              fullWidth
              label="Priority"
              value={priority}
              onChange={(e) => setPriority(e.target.value)}
              error={bannerFormError?.priority}
            />
          ) : (
            ''
          )}
          {applicationType === 'app' || applicationType === 'both' ? (
            <Grid item xs={12}>
              <InputLabel id="demo-simple-select-label" shrink={true}>
                Mobile App Banner
              </InputLabel>
              <TextField
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                name="image"
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                onChange={getFile}
                ref={el}
                error={bannerFormError?.image}
                helperText={bannerFormError?.image}
              />
            </Grid>
          ) : (
            ''
          )}
          {applicationType === 'website' || applicationType === 'both' ? (
            <Grid item xs={12}>
              <InputLabel id="demo-simple-select-label" shrink={true}>
                Website Banner
              </InputLabel>
              <TextField
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                name="web_image"
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                onChange={getFile}
                ref={el}
                error={bannerFormError?.image_web}
                helperText={bannerFormError?.image_web}
              />
            </Grid>
          ) : (
            ''
          )}
        </>
      ) : (
        <Grid item xs={12}>
          <InputLabel id="demo-simple-select-label" shrink={true}>
            Doctor App Banner
          </InputLabel>
          <TextField
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            name="image_doctor_app"
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            onChange={getFile}
            ref={el}
            error={bannerFormError?.image_doctor_app}
            helperText={bannerFormError?.image_doctor_app}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Link Type</InputLabel>
          <Select
            fullWidth
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Link Type"
            value={linkType}
            onChange={(e) => {
              if (
                e.target.value === 'Test' ||
                e.target.value === 'Package' ||
                e.target.value === 'Blog' ||
                e.target.value === 'Category' ||
                e.target.value === 'NewsEvent' ||
                e.target.value === 'MembershipCard'
              ) {
                setIsTestOrPackageType(true);
              } else {
                setIsTestOrPackageType(false);
              }
              setIsDefaultValue(false);
              setlink_value('');
              setTestData([]);
              setLinkType(e.target.value);
            }}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="Test">Test</MenuItem>
            <MenuItem value="Package">Package</MenuItem>
            <MenuItem value="Blog">Blog</MenuItem>
            <MenuItem value="NewsEvent">NewsEvent</MenuItem>
            <MenuItem value="Category">Category</MenuItem>
            <MenuItem value="MembershipCard">Membership Card</MenuItem>
            <MenuItem value="Coupons">Coupon</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {isTestOrPackageType === true && isDefaultValue && id && (
        <Grid item xs={12}>
          <Autocomplete
            key={linkType}
            disablePortal
            id="combo-box-demo"
            onChange={(event, value) => setlink_value(value)}
            options={testData}
            getOptionLabel={(option) =>
              linkType !== 'Blog' && linkType !== 'NewsEvent'
                ? linkType !== 'Category'
                  ? option.NAME
                  : option.name
                : option.title
            }
            sx={{ width: '100%' }}
            defaultValue={link_value}
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                }}
                error={bannerFormError?.link_value}
                helperText={bannerFormError?.link_value}
                label={`Search ${linkType}`}
              />
            )}
          />
        </Grid>
      )}

      {isTestOrPackageType === true && isDefaultValue === false && id && (
        <Grid item xs={12}>
          <Autocomplete
            key={linkType}
            disablePortal
            id="combo-box-demo"
            onChange={(event, value) => setlink_value(value)}
            options={testData}
            getOptionLabel={(option) =>
              linkType !== 'Blog' && linkType !== 'NewsEvent'
                ? linkType !== 'Category'
                  ? option.NAME
                  : option.name
                : option.title
            }
            sx={{ width: '100%' }}
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                }}
                error={bannerFormError?.link_value}
                helperText={bannerFormError?.link_value}
                label={`Search ${linkType}`}
              />
            )}
          />
        </Grid>
      )}

      {isTestOrPackageType === true && id === '' && (
        <Grid item xs={12}>
          <Autocomplete
            key={linkType}
            disablePortal
            id="combo-box-demo"
            onChange={(event, value) => setlink_value(value)}
            options={testData}
            getOptionLabel={(option) =>
              linkType !== 'Blog' && linkType !== 'NewsEvent'
                ? linkType !== 'Category'
                  ? option.NAME
                  : option.name
                : option.title
            }
            sx={{ width: '100%' }}
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                }}
                error={bannerFormError?.link_value}
                helperText={bannerFormError?.link_value}
                label={`Search ${linkType}`}
              />
            )}
          />
        </Grid>
      )}

      {linkType === 'Other' && (
        <Grid item xs={12}>
          <Controls.Input
            name="link"
            fullWidth
            label="Banner Link"
            value={link_value}
            error={bannerFormError?.link_value}
            helperText={bannerFormError?.link_value}
            onChange={(e) => setlink_value(e.target.value)}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Box>
          <LoadingButton
            size="medium"
            type="submit"
            variant="contained"
            endIcon
            disabled={loadingDisable}
          >
            {id ? 'Update' : 'Add'}
          </LoadingButton>
        </Box>
      </Grid>
    </Form>
  );
}
